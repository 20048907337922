import { Button } from "@mui/material";
import { Step1 } from "pages/setp1";
import { Step2 } from "pages/step2";
import { Step3 } from "pages/step3";
import { Step4 } from "pages/step4";
import { Step9 } from "pages/step9";
import React from "react";
// import PropTypes from 'prop-types';

export default function NavigationController() {
  const [index, setIndex] = React.useState(0);

  const content = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step9 />];

  function handleNext() {
    setIndex((i) => i + 1);
  }

  function handlePrevious() {
    setIndex((i) => i - 1);
  }

  return (
    <div className="content">
      <div>
        {content[index]}
        <div
          className="flex end"
          style={{
            marginTop: 8,
            position: "sticky",
            bottom: 0,
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(10px)",
            WebKitBackdropFilter: "blur(10px)",
            padding: 16,
          }}
        >
          {index > 0 ? (
            <Button
              onClick={handlePrevious}
              variant="outlined"
              color="secondary"
              className="button"
              style={{ marginRight: 8 }}
            >
              Previous
            </Button>
          ) : (
            []
          )}
          {index < content.length ? (
            <Button
              onClick={handleNext}
              variant="outlined"
              color="primary"
              className="button"
            >
              Next
            </Button>
          ) : (
            []
          )}
        </div>
      </div>
    </div>
  );
}
