// import PropTypes from 'prop-types';
// material-ui
import { Button, InputBase, Typography } from "@mui/material";
import React from "react";
// styles

export default function CodeInput({ onSubmit }) {
  const [code, setCode] = React.useState("");

  function handleChange(e) {
    setCode(e.target.value);
  }

  return (
    <div style={{ marginTop: 16, maxWidth: 350 }}>
      <Typography variant="caption">Enter your access code:</Typography>
      <div style={{ display: "flex" }}>
        <InputBase
          onChange={handleChange}
          className="input"
          placeholder="XXX-XXX"
        />
        <Button onClick={() => onSubmit(code)} className="buttonInput">
          Access
        </Button>
      </div>
    </div>
  );
}
