import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setArea } from "redux/answersSlice";
import { areasOptions } from "../constants";

export function Step2() {
  const academy = useSelector((state) => state.academy);
  const answers = useSelector((state) => state.answers);
  const { areas } = answers;
  const dispatch = useDispatch();

  return (
    <Paper variant="outlined" className="paper">
      <Typography variant="h6">
        Please select the following areas that need updates/corrections.
      </Typography>
      <br />
      <FormGroup>
        {areasOptions.map((a) => (
          <FormControlLabel
            style={{ paddingBottom: 16 }}
            control={
              <Checkbox
                onChange={(e) =>
                  dispatch(setArea({ id: a.id, value: e.target.checked }))
                }
                checked={areas.indexOf(a.id) !== -1}
              />
            }
            label={a.content}
          />
        ))}
      </FormGroup>
    </Paper>
  );
}
