// import PropTypes from 'prop-types';
// material-ui
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function CheckboxAutocomplete({
  options,
  value,
  optionKey,
  groupBy,
  onAdd,
  onDelete,
  label,
}) {
  // const [ state, setState ] = React.useState(initialValue);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      disableClearable
      groupBy={groupBy}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Your answer..." />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option[optionKey]}
        </li>
      )}
      onChange={(e, v, r) => {
        switch (r) {
          case "removeOption":
            onDelete(value.filter((x) => !v.includes(x))[0]);
            break;
          case "selectOption":
            onAdd(v.filter((x) => !value.includes(x))[0]);
            break;
          default:
        }
      }}
      getOptionLabel={(option) => {
        return `${option[optionKey]}`;
      }}
    />
  );
}
