import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: "",
  areas: [],
  musicDepartmentDescription: "",
  musicDepartmentOfferings: [],
  otherMusicDepartmentOfferings: "",
};

export const answerSlice = createSlice({
  name: "answer",
  initialState,
  reducers: {
    setMusicDepartmentDescription: (state, action) => {
      state.musicDepartmentDescription = action.payload;
    },
    setArea: (state, action) => {
      if (action.payload.value) {
        state.areas.push(action.payload.id);
      } else {
        const index = state.areas.indexOf(action.payload.id);
        if (index > -1) {
          state.areas.splice(index, 1);
        }
      }
    },
    setMusicDepartmentOfferings: (state, action) => {
      if (action.payload.value && state.musicDepartmentOfferings.length < 10) {
        state.musicDepartmentOfferings.push(action.payload.id);
      } else {
        const index = state.musicDepartmentOfferings.indexOf(action.payload.id);
        if (index > -1) {
          state.musicDepartmentOfferings.splice(index, 1);
        }
      }
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setOtherMusicDepartmentOfferings: (state, action) => {
      state.otherMusicDepartmentOfferings = action.payload;
    },
  },
});

export const {
  setMusicDepartmentDescription,
  setArea,
  setCode,
  setMusicDepartmentOfferings,
  setOtherMusicDepartmentOfferings,
} = answerSlice.actions;

export default answerSlice.reducer;
