import { configureStore } from "@reduxjs/toolkit";
import academyReducer from "./academySlice";
import answersSlice from "./answersSlice";
import { mainMiddleware } from "./mainMiddleware";

export const store = configureStore({
  reducer: {
    academy: academyReducer,
    answers: answersSlice,
  },
  middleware: [mainMiddleware],
});
