import { Grid, Paper, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setAcademyAddress,
  setAcademyCity,
  setAcademyCountry,
  setAcademyName,
  setAcademyPicture,
  setAcademyState,
  setAcademyWebsite,
  setAcademyZipcode,
} from "redux/academySlice";

export function Step3() {
  const academy = useSelector((state) => state.academy);
  const answers = useSelector((state) => state.answers);

  const {
    musicDepartmentDescription,
    musicDepartmentOfferings,
    otherMusicDepartmentOfferings,
  } = answers;

  const dispatch = useDispatch();

  return (
    <div>
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct name of the College/University?
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={academy.name}
          maxRows={10}
          onChange={(e) => dispatch(setAcademyName(e.target.value))}
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct website for the College/University?
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={academy.website}
          maxRows={10}
          onChange={(e) => dispatch(setAcademyWebsite(e.target.value))}
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct logo for the College/University?
        </Typography>
        <br />
        <img src={academy.picture} alt="logo" height="80px" />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={academy.picture}
          maxRows={10}
          onChange={(e) => dispatch(setAcademyPicture(e.target.value))}
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct address for the College/University?
        </Typography>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant="filled"
              placeholder="Street"
              label="Street"
              fullWidth
              value={academy.street}
              maxRows={10}
              onChange={(e) => dispatch(setAcademyAddress(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="City"
              fullWidth
              value={academy.city}
              maxRows={10}
              onChange={(e) => dispatch(setAcademyCity(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="Zipcode"
              placeholder="Zipcode"
              fullWidth
              value={academy.zipcode}
              maxRows={10}
              onChange={(e) => dispatch(setAcademyZipcode(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="State"
              fullWidth
              value={academy.state}
              maxRows={10}
              onChange={(e) => dispatch(setAcademyState(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="Country"
              fullWidth
              value={academy.country}
              maxRows={10}
              onChange={(e) => dispatch(setAcademyCountry(e.target.value))}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
