import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setMusicDepartmentDescription,
  setMusicDepartmentOfferings,
  setOtherMusicDepartmentOfferings,
} from "redux/answersSlice";
import { musicDepartmentOfferingsOptions } from "../constants";

export function Step9() {
  const academy = useSelector((state) => state.academy);
  const answers = useSelector((state) => state.answers);

  const {
    musicDepartmentDescription,
    musicDepartmentOfferings,
    otherMusicDepartmentOfferings,
  } = answers;

  const dispatch = useDispatch();

  return (
    <div>
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          Please insert a short paragraph about the music department (500 words
          max).
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          multiline
          value={musicDepartmentDescription}
          maxRows={10}
          onChange={(e) =>
            dispatch(setMusicDepartmentDescription(e.target.value))
          }
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          Select from the following list up to 10 offerings the music department
          has that you would like us to feature.
        </Typography>
        <br />
        <FormGroup>
          {musicDepartmentOfferingsOptions.map((a) => (
            <FormControlLabel
              style={{ paddingBottom: 16 }}
              control={
                <Checkbox
                  onChange={(e) =>
                    dispatch(
                      setMusicDepartmentOfferings({
                        id: a.id,
                        value: e.target.checked,
                      })
                    )
                  }
                  checked={musicDepartmentOfferings.indexOf(a.id) !== -1}
                />
              }
              label={a.content}
            />
          ))}
        </FormGroup>
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          Please use this space to indicate anything else you would like us to
          consider adding.
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={otherMusicDepartmentOfferings}
          onChange={(e) =>
            dispatch(setOtherMusicDepartmentOfferings(e.target.value))
          }
        />
      </Paper>
    </div>
  );
}
