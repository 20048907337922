import { Divider, FormControl } from "@material-ui/core";
import {
  AppBar,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import divisions from "data/divisions";
import programTypes from "data/programTypes";
import CheckboxAutocomplete from "hooks/CheckboxAutocomplete";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProgram } from "redux/academySlice";

export function Step4() {
  const academy = useSelector((state) => state.academy);
  const answers = useSelector((state) => state.answers);
  const { areas } = answers;
  const [tabIndex, setTabIndex] = React.useState(0);

  const { programs } = academy;

  const {
    musicDepartmentDescription,
    musicDepartmentOfferings,
    otherMusicDepartmentOfferings,
  } = answers;

  const dispatch = useDispatch();

  const { setIsOpen } = useTour();

  React.useEffect(() => {
    setIsOpen(true);
  }, []);

  const addDivision = (d) => {
    const _programs = [...(programs[tabIndex].divisions ?? [])];
    _programs.push(d);
    dispatch(setProgram({ value: { divisions: _programs }, index: tabIndex }));
  };

  const removeDivision = (d) => {
    const _programs = [...(programs[tabIndex].divisions ?? [])];
    const _index = _programs.findIndex((e) => e.id === d.id);
    if (_index !== -1) {
      _programs.splice(_index, 1);
      dispatch(
        setProgram({ value: { divisions: _programs }, index: tabIndex })
      );
    }
  };

  const getDivisionFullSection = (programTypeID) => {
    const _programType = programTypes.find((d) => d.id === programTypeID);

    return (
      <>
        <Divider style={{ margin: "50px 0px" }} />
        <Typography variant="h3" style={{ fontWeight: "600" }}>
          {_programType.name}
        </Typography>
        <br />
        <Paper variant="outlined" className="paper">
          <Typography variant="h6">
            Please select from the following programs that best describes what{" "}
            {_programType.name} can major in or obtain their degree in.
          </Typography>
          <br />
          <CheckboxAutocomplete
            options={divisions.filter((d) => d.programTypeID === programTypeID)}
            value={
              programs[tabIndex].divisions?.filter(
                (d) => d.programTypeID === programTypeID
              ) ?? []
            }
            label={_programType.name}
            optionKey="name"
            groupBy={(option) => option.categoryName}
            onDelete={removeDivision}
            onAdd={addDivision}
          />
          <br />
        </Paper>
        <br />
        {programs[tabIndex].divisions
          ?.filter((e) => e.programTypeID === programTypeID)
          ?.map((d) => (
            <Paper variant="outlined" className="paper">
              <Typography variant="h6">
                If there are any concentration, specialization options, or
                additional information for undergraduate degrees in the area of
                "{d.name}", please write them below.
              </Typography>
              <br />
              <TextField
                variant="filled"
                label="Your answer..."
                fullWidth
                value={programs[tabIndex][`${d.name}MoreDetails`] ?? ""}
                maxRows={10}
                onChange={(e) =>
                  dispatch(
                    setProgram({
                      value: { [`${d.name}MoreDetails`]: e.target.value },
                      index: tabIndex,
                    })
                  )
                }
              />
              <br />
            </Paper>
          ))}
        <br />
        <Paper variant="outlined" className="paper">
          <Typography variant="h6">
            Please write out any other {_programType.name} or other programs
            your music department offers.
          </Typography>
          <br />
          <TextField
            variant="filled"
            placeholder="Your answer"
            fullWidth
            value={
              programs[tabIndex][`${programTypeID}_otherMajorsPrograms`] ?? ""
            }
            maxRows={10}
            onChange={(e) =>
              dispatch(
                setProgram({
                  value: {
                    [`${programTypeID}_otherMajorsPrograms`]: e.target.value,
                  },
                  index: tabIndex,
                })
              )
            }
          />
        </Paper>
        <br />
        <Paper variant="outlined" className="paper">
          <Typography variant="h6">
            Are there music-related degrees offered from a different department?
          </Typography>
          <br />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={
                programs[tabIndex][
                  `${programTypeID}_musicRelatedDegreesFromDifferentDepartment`
                ]
              }
              onChange={(e, v) => {
                console.log(Boolean(v));
                dispatch(
                  setProgram({
                    value: {
                      [`${programTypeID}_musicRelatedDegreesFromDifferentDepartment`]:
                        v === "true",
                    },
                    index: tabIndex,
                  })
                );
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Paper>
        {programs[tabIndex][
          `${programTypeID}_musicRelatedDegreesFromDifferentDepartment`
        ] ? (
          <>
            <br />
            <Paper variant="outlined" className="paper">
              <Typography variant="h6">
                Please indicate which department(s), what degree(s), and what
                major/area of study
              </Typography>
              <br />
              <TextField
                variant="filled"
                placeholder="Your answer"
                fullWidth
                value={
                  programs[tabIndex][
                    `${programTypeID}_musicRelatedDegreesFromDifferentDepartmentDetails`
                  ] ?? ""
                }
                maxRows={10}
                onChange={(e) =>
                  dispatch(
                    setProgram({
                      value: {
                        [`${programTypeID}_musicRelatedDegreesFromDifferentDepartmentDetails`]:
                          e.target.value,
                      },
                      index: tabIndex,
                    })
                  )
                }
              />
            </Paper>
          </>
        ) : (
          []
        )}
      </>
    );
  };

  return (
    <>
      <AppBar
        variant="outlined"
        position="fixed"
        className="select-tabs"
        style={{ background: "white" }}
      >
        <Tabs
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          {programs.map((p) => (
            <Tab label={p.name} />
          ))}
        </Tabs>
      </AppBar>
      <Paper style={{ marginTop: 64 }} variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct name of the music department?
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={programs[tabIndex].name}
          maxRows={10}
          onChange={(e) =>
            dispatch(
              setProgram({ value: { name: e.target.value }, index: tabIndex })
            )
          }
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct website for the music department?
        </Typography>
        <br />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={programs[tabIndex].website}
          maxRows={10}
          onChange={(e) =>
            dispatch(
              setProgram({
                value: { website: e.target.value },
                index: tabIndex,
              })
            )
          }
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct logo for the music department?
        </Typography>
        <br />
        <img src={programs[tabIndex].picture} alt="logo" height="80px" />
        <TextField
          variant="filled"
          placeholder="Your answer"
          fullWidth
          value={programs[tabIndex].picture}
          maxRows={10}
          onChange={(e) =>
            dispatch(
              setProgram({
                value: { picture: e.target.value },
                index: tabIndex,
              })
            )
          }
        />
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          What is the correct PHYSICAL address for the music department?
        </Typography>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant="filled"
              placeholder="Street"
              label="Street"
              fullWidth
              value={programs[tabIndex].street}
              maxRows={10}
              onChange={(e) =>
                dispatch(
                  setProgram({
                    value: { street: e.target.value },
                    index: tabIndex,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="City"
              fullWidth
              value={programs[tabIndex].city}
              maxRows={10}
              onChange={(e) =>
                dispatch(
                  setProgram({
                    value: { city: e.target.value },
                    index: tabIndex,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="Zipcode"
              placeholder="Zipcode"
              fullWidth
              value={programs[tabIndex].zipcode}
              maxRows={10}
              onChange={(e) =>
                dispatch(
                  setProgram({
                    value: { zipcode: e.target.value },
                    index: tabIndex,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="State"
              fullWidth
              value={programs[tabIndex].state}
              maxRows={10}
              onChange={(e) =>
                dispatch(
                  setProgram({
                    value: { state: e.target.value },
                    index: tabIndex,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="filled"
              label="Country"
              fullWidth
              value={programs[tabIndex].country}
              maxRows={10}
              onChange={(e) =>
                dispatch(
                  setProgram({
                    value: { country: e.target.value },
                    index: tabIndex,
                  })
                )
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Paper variant="outlined" className="paper">
        <Typography variant="h6">
          Please select all of the undergraduate degrees that the music
          department offers.
        </Typography>
        <br />
        <CheckboxAutocomplete
          options={divisions.filter((d) => d.programTypeID === 13)}
          value={
            programs[tabIndex].divisions?.filter(
              (d) => d.programTypeID === 13
            ) ?? []
          }
          label="Undergraduate Degrees"
          optionKey="name"
          groupBy={(option) => option.categoryName}
          onDelete={removeDivision}
          onAdd={addDivision}
        />
        <br />
      </Paper>
      {areas.indexOf(3) !== -1 && getDivisionFullSection(14)}
      {areas.indexOf(4) !== -1 && getDivisionFullSection(14)}
      {areas.indexOf(5) !== -1 && getDivisionFullSection(14)}
    </>
  );
}
