import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes, Typography } from "@mui/material";
import { blueGrey, orange } from "@mui/material/colors";
import { TourProvider } from "@reactour/tour";
import Logo from "assets/logo.png";
import NavigationController from "hooks/NavigationController";
import moment from "moment";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { CodeAccess } from "./pages/codeAccess";
import { store } from "./redux/store";

const theme = createTheme({
  palette: {
    primary: orange,
    secondary: blueGrey,
  },
  button: {
    borderRadius: 12,
  },
  typography: {
    button: {
      textTransform: "none",
      fontWeight: 400,
      fontSize: 14,
    },
    allVariants: {
      color: "#46505A",
    },

    h1: {
      fontWeight: 500,
      lineHeight: 1,
      color: "#272838",
    },
    h2: {
      fontWeight: 500,
      lineHeight: 1,
      color: "#272838",
    },
    h4: {
      fontWeight: 500,
      lineHeight: 1,
      color: "#272838",
    },
    subtitle1: {
      color: "black",
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
    fontFamily: "ebony, sans-serif;",
  },
});

const uiTheme = responsiveFontSizes(theme);

const steps = [
  {
    selector: ".select-tabs",
    content: "Select the music department you want to verify/update",
  },
];

function App() {
  return (
    <TourProvider steps={steps} showNavigation={false} showBadge={false}>
      <ThemeProvider theme={uiTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <div className="root">
              <Routes>
                <Route path="/" element={<CodeAccess />} />
                <Route path="/form" element={<NavigationController />} />
              </Routes>
              <div className="footer">
                <img src={Logo} alt="logo" height="50px" />
                <Typography
                  variant="body2"
                  display="block"
                  style={{ opacity: 0.58 }}
                >
                  Copyright © {moment().format("YYYY")} College Prep for
                  Musicians.
                </Typography>
              </div>
            </div>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </TourProvider>
  );
}

export default App;
