import { Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export function Step1() {
  const academy = useSelector((state) => state.academy);

  return (
    <Paper variant="outlined" className="paper">
      <Typography variant="h6">CPFM Colleges Form</Typography>
      <br />
      <Typography variant="body2">
        Thank you for taking the time to help College Prep for Musicians
        accurately represent the data on your college music department. We have
        gathered data on your college and music department in order to show our
        audience information about degree and certificate programs offered at{" "}
        {academy.name}. The information you provide us will ensure that College
        Prep for Musicians can display the most accurate and up to date
        information about {academy.name} and the music department.
      </Typography>
      <br />
      <Typography variant="body2">
        The first sections of the survey will give you the opportunity to
        correct the information we have listed about {academy.name} and the
        music department. This information includes website urls, logos/icons,
        physical/mailing addresses, undergraduate and graduate degrees, majors
        and areas of concentration, and certificates. Due to the variety of
        degrees a college may award, the degrees we have listed for your music
        department may be simplified, so this section will give you the
        opportunity to expand on and list any additional information relevant to
        the degrees or certificates offered.
      </Typography>
      <br />
      <Typography variant="body2">
        If the information is listed correctly, or you have finished submitting
        the corrections in the first sections, you will move onto the last
        section of the survey where you are able to upload additional
        information to make your college stand out. This section is where you
        can tell us anything else about the music department that may help
        prospective students decide on applying and enrolling in {academy.name}.
      </Typography>
      <br />
      <Typography variant="body2">Thank you again for your time!</Typography>
    </Paper>
  );
}
