import { createSlice } from "@reduxjs/toolkit";
import example from "./example.json";

const initialState = {
  ...example,
  initial: example,
};

export const academySlice = createSlice({
  name: "academy",
  initialState,
  reducers: {
    setAcademyName: (state, action) => {
      state.name = action.payload;
    },
    setAcademyWebsite: (state, action) => {
      state.website = action.payload;
    },
    setAcademyPicture: (state, action) => {
      state.picture = action.payload;
    },
    setAcademyAddress: (state, action) => {
      state.address = action.payload;
    },
    setAcademyZipcode: (state, action) => {
      state.zipcode = action.payload;
    },
    setAcademyCity: (state, action) => {
      state.city = action.payload;
    },
    setAcademyState: (state, action) => {
      state.state = action.payload;
    },
    setAcademyCountry: (state, action) => {
      state.country = action.payload;
    },
    setProgram: (state, action) => {
      state.programs[action.payload.index] = {
        ...state.programs[action.payload.index],
        ...action.payload.value,
      };
    },
  },
});

export const {
  setAcademyName,
  setAcademyWebsite,
  setAcademyPicture,
  setAcademyAddress,
  setAcademyZipcode,
  setAcademyCity,
  setAcademyState,
  setAcademyCountry,
  setProgram,
} = academySlice.actions;

export default academySlice.reducer;
